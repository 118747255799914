/**
 * SiteHeader component.
 * @module components/theme/Site/SiteHeader/SiteHeader
 */

import React from 'react';
import NoSSR from 'react-no-ssr';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { MediaQueryConfig } from '../../../../helpers/GlobalStyle/GlobalStyle';
import './SiteHeader.css';

const SiteHeader = ({ ctype, logoSP }) => {
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });

  const folderList = ['OuCategoryFolder', 'OuNewsFolder', 'OuEventFolder'];

  return (
    <header className="SiteHeader">
      <NoSSR>
        {isSP && (
          <div className="siteHeaderInner">
            <div className="logo">
              <Link to={flattenToAppURL(logoSP?.url ?? '')}>
                <img
                  alt={logoSP?.alt}
                  src={flattenToAppURL(
                    folderList.includes(ctype)
                      ? logoSP?.src_for_folder ?? ''
                      : logoSP?.src ?? '',
                  )}
                  width={158}
                />
              </Link>
            </div>
          </div>
        )}
      </NoSSR>
    </header>
  );
};

const SiteHeaderContainer = connect(
  (state, props) => {
    const locale = state?.content?.data?.language?.token ?? 'ja';
    return {
      logoSP:
        state?.siteMainInfo?.result?.data?.attributes?.locale?.[locale]?.header
          ?.logo_sp,
    };
  },
  (dispatch, props) => {
    return {};
  },
)(SiteHeader);

export default SiteHeaderContainer;
