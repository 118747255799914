/**
 * SiteHeaderForError component.
 * @module components/theme/Site/SiteHeader/SiteHeaderForError
 */

import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getLocaleFromPathname } from '../../../../helpers/Locale/Locale';
import './SiteHeaderForError.css';
import config from '@plone/volto/registry';

const SiteHeaderForError = () => {
  let lang;
  if (__CLIENT__) {
    lang = getLocaleFromPathname(
      __CLIENT__ ? window?.location.pathname : null,
      config.settings.defaultLanguage,
    );
  }
  return (
    <header className="SiteHeaderForError">
      <div className="logo">
        <Link to={flattenToAppURL(`/${lang}`)}>
          <img alt="" width={158} src={flattenToAppURL('/assets/images/header/logo.svg')} />
        </Link>
      </div>
    </header>
  );
};

export default injectIntl(SiteHeaderForError);
